import {
  TIME_ZONE, formatStopWatch, sentenceCase, toBoolean,
} from '@emobg/web-utils';

export { contentCells } from './contentCells';
export { exportColumns } from './exportColumns';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'user_name',
  'user_fk',
  'user_uuid',
  'start_utc',
  'end_utc',
  'location',
  'location_fk',
  'location_uuid',
  'city',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_fk',
  'vehicle_uuid',
  'vehicle_plate',
  'usage_status',
  'booking_type',
  'is_unavailability',
  'is_automatic',
  'alert_reason',
  'real_start',
  'real_end',
  'created_at',
  'updated_at',
  'profile',
  'cs_operator',
  'mileage',
  'mileage_unit',
  'duration_in_secs',
  'real_duration_in_secs',
  'has_fraud',
  'fraud',
  // facets
  'net_booking_cost',
  'gross_booking_cost',
  'profile_cost_allocation',
  'start_ts',
  'end_ts',
  'status',
  'non_connected',
  'is_connected_car_platform',
];
export const restrictSearchableAttributes = [
  'id',
  'user_name',
  'user_fk',
  'start_utc',
  'end_utc',
  'location',
  'city',
  'vehicle_brand',
  'vehicle_model',
  'vehicle_fk',
  'vehicle_plate',
  'usage_status',
  'booking_type',
  'is_unavailability',
  'alert_reason',
  'real_start',
  'real_end',
  'created_at',
  'updated_at',
  'profile',
  'cs_operator',
  'start_ts',
  'end_ts',
  'status',
];

export function bookingListFacets(operatorTimezone, allowNonConnectedVehicles) {
  const nonConnectedFacet = {
    type: 'refinementList',
    props: {
      title: 'Non-connected booking',
      attributeName: 'non_connected',
      transformValue: value => (toBoolean(value) ? 'Yes' : 'No'),
    },
  };

  const facets = [
    {
      type: 'DateIntervalsRange',
      props: {
        title: 'Dates',
        dateStart: 'start_ts',
        dateEnd: 'end_ts',
        labels: {
          from: 'From',
          to: 'To',
          setToday: 'Set to today',
          none: 'None',
          rangeOverlap: 'Active Bookings',
          dateEnd: 'Booking end date',
          dateStart: 'Booking start date',
          infoNone: 'No date filters applied',
          infoRangeOverlap: 'Bookings being active (in progress) in selected period',
          infoDateStart: 'Bookings starting in selected period',
          infoDateEnd: 'Bookings ending in selected period',
        },
        timezone: operatorTimezone || TIME_ZONE.default,
        from: null,
        to: null,
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Booking duration',
        attributeName: 'duration_in_secs',
        toggled: false,
        showTooltip: true,
        format: value => (value < 60 ? '0 min' : formatStopWatch(value)),
        pipValues: [],
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Real duration',
        attributeName: 'real_duration_in_secs',
        toggled: false,
        showTooltip: true,
        format: value => (value < 60 ? '0 min' : formatStopWatch(value)),
        pipValues: [],
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Profile',
        attributeName: 'profile',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Alerts',
        attributeName: 'alert_reason',
        showTooltip: true,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Fraud reported',
        attributeName: 'has_fraud',
        transformValue: value => (toBoolean(value) ? 'Yes' : 'No'),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Operator',
        attributeName: 'cs_operator',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'city',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Location',
        attributeName: 'location',
        showTooltip: true,
        showMoreLimit: 1000,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'System status',
        attributeName: 'status',
        transformValue: sentenceCase,
        icon: {
          icon: 'system-status',
          color: 'black',
          size: '16',
        },
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Vehicle status',
        attributeName: 'usage_status',
        transformValue: sentenceCase,
        toggled: false,
        icon: {
          icon: 'vehicle-status',
          color: 'black',
          size: '16',
        },
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Booking type',
        attributeName: 'booking_type',
        transformValue: sentenceCase,
        toggled: false,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Unavailability',
        attributeName: 'is_unavailability',
        transformValue: sentenceCase,
        toggled: false,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Device type',
        attributeName: 'is_connected_car_platform',
        transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
      },
    },

  ];

  if (allowNonConnectedVehicles) {
    facets.push(nonConnectedFacet);
  }

  return facets;
}
