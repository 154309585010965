import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatStopWatch,
  formatUtc,
  reformatDateTime,
  sentenceCase,
  toBoolean,
} from '@emobg/web-utils';
import { BOOKING_FRAUD_COSTS } from '../const/booking.const';

const secondsToHours = seconds => (seconds ? (seconds / 3600).toFixed(2) : FALLBACK_MESSAGE.dash);

const getFraudCostsColumns = currencySymbol => map(BOOKING_FRAUD_COSTS, cost => ({
  title: `Fraud cost - ${sentenceCase(cost)}`,
  transformResult: booking => {
    const fraudCosts = get(booking, 'fraud.costs');
    const costObject = find(fraudCosts, { type: cost });
    return costObject ? `${costObject.cost}${currencySymbol}` : FALLBACK_MESSAGE.dash;
  },
}));

const nonConnectedColumn = {
  attributeName: 'non_connected',
  title: 'Non-connected booking',
  transformValue: value => (value ? 'Yes' : 'No'),
};

export function exportColumns(options) {
  const {
    operatorTimezone,
    allowNonConnectedVehicles,
    currencySymbol,
  } = options;

  const columns = [
    {
      attributeName: 'id',
      title: 'ID',
      transformResult: result => `#${result.id}`,
    },
    {
      attributeName: 'user_name',
      title: 'User',
    },
    {
      attributeName: 'profile_cost_allocation',
      title: 'Profile cost allocation',
      transformValue: profileCostAllocation => profileCostAllocation || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'start_ts',
      title: 'Start date',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'end_ts',
      title: 'End date',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'duration_in_secs',
      title: 'Booking duration',
      transformValue: formatStopWatch,
    },
    {
      title: 'Booking duration (number format)',
      transformResult: result => secondsToHours(result.duration_in_secs),
    },
    {
      attributeName: 'location',
      title: 'Location',
      transformResult: result => `${result.location.replace(/"/g, '\'')}, ${result.city}`,
    },
    {
      attributeName: 'vehicle_brand',
      title: 'Vehicle brand',
    },
    {
      attributeName: 'vehicle_model',
      title: 'Vehicle model',
    },
    {
      attributeName: 'vehicle_plate',
      title: 'License plate',
    },
    {
      attributeName: 'usage_status',
      title: 'Booking status',
    },
    {
      attributeName: 'booking_type',
      title: 'Booking type',
      transformValue: sentenceCase,
    },
    {
      attributeName: 'real_start',
      title: 'Real start',
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'real_end',
      title: 'Real end',
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'real_duration_in_secs',
      title: 'Real duration',
      transformValue: formatStopWatch,
    },
    {
      title: 'Real duration (number format)',
      transformResult: result => secondsToHours(result.real_duration_in_secs),
    },
    {
      attributeName: 'mileage',
      title: 'Mileage',
      transformValue: mileage => mileage || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'mileage_unit',
      title: 'Mileage unit',
      transformValue: mileageUnit => mileageUnit || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'created_at',
      title: 'Creation date',
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'updated_at',
      title: 'Last update date',
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'net_booking_cost',
      title: 'Net booking cost',
      transformValue: netBookingCost => netBookingCost || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'gross_booking_cost',
      title: 'Gross Booking cost',
      transformValue: grossBookingCost => grossBookingCost || FALLBACK_MESSAGE.dash,
    },
    {
      attributeName: 'profile',
      title: 'User profile',
      transformValue: sentenceCase,
    },
    {
      attributeName: 'cs_operator',
      title: 'Operator',
    },
    {
      attributeName: 'is_connected_car_platform',
      title: 'Device type',
      transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
    },
    {
      attributeName: 'has_fraud',
      title: 'Fraud reported',
      transformValue: value => {
        if (isUndefined(value)) {
          return FALLBACK_MESSAGE.dash;
        }
        return value ? 'Yes' : 'No';
      },
    },
    {
      title: 'Fraud reasons',
      transformResult: booking => {
        const reasons = get(booking, 'fraud.reasons');
        const handleReason = (result, reason, index) => `${result}${sentenceCase(reason)}${index < reasons.length - 1 ? ', ' : ''}`;
        return !isEmpty(reasons) ? reduce(reasons, handleReason, '') : FALLBACK_MESSAGE.dash;
      },
    },
    ...getFraudCostsColumns(currencySymbol),
    {
      attributeName: 'fraud.total_cost',
      title: 'Total fraud cost',
      transformValue: value => `${value}${currencySymbol}`,
    },
  ];

  if (allowNonConnectedVehicles) {
    columns.push(nonConnectedColumn);
  }

  return columns;
}
