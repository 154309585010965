import concat from 'lodash/concat';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import join from 'lodash/join';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatStopWatch,
  formatUtc,
  reformatDateTime,
  sentenceCase,
  toBoolean,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import BookingTypeBadge from '@domains/Carsharing/components/BookingTypeBadge/BookingTypeBadge';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharing from '@domains/Carsharing/router/CarsharingRouterMap';
import { GRAYSCALE } from '@emobg/vue-base';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';
import { AlertsTemplate, BookingStatusTemplate, LocationTemplate } from '@/templates';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import VehicleLink from '../../components/VehicleLink';

const nonConnectedCell = {
  attributeName: 'non_connected',
  title: 'Non-connected booking',
  type: RECORD_TYPES.component,
  component: BadgeComponent,
  displayPriority: 0,
  props: result => ({
    text: get(result, 'non_connected', false) ? 'Yes' : 'No',
    color: GRAYSCALE.ground,
  }),
};

const userCell = {
  title: 'User',
  displayPriority: 1,
  minWidth: 180,
  type: RECORD_TYPES.component,
  component: PermissionLink,
  props: result => ({
    classLink: 'emobg-link-primary emobg-body-2',
    to: {
      name: crm.users.detail.bookings,
      params: {
        userUuid: result.user_uuid,
      },
    },
    linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
    text: result.user_name,
  }),
};

const locationCell = {
  attributeName: 'location',
  title: 'Location',
  displayPriority: 1,
  minWidth: 200,
  transformResult: result => `${result.location.replace(/"/g, "'")}, ${result.city}`,
  type: RECORD_TYPES.component,
  component: LocationTemplate,
  props: result => ({ address: result }),
};

export function contentCells(operatorTimezone, allowNonConnectedVehicles, isUserVisible = true, isLocationVisible = true) {
  const cells = [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: carsharing.bookings.detail.index,
          params: {
            bookingUuid: result.uuid,
          },
        },
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: `#${result.id}`,
      }),
      minWidth: 100,
    },
    {
      attributeName: 'start_ts',
      title: 'Start date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'end_ts',
      title: 'End date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'duration_in_secs',
      title: 'Booking duration',
      displayPriority: 1,
      minWidth: 180,
      transformValue: formatStopWatch,
    },
    {
      attributeName: 'usage_status',
      title: 'Booking status',
      displayPriority: 1,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: BookingStatusTemplate,
      props: result => ({ result }),
    },
    {
      attributeName: 'vehicle_brand',
      title: 'Vehicle',
      displayPriority: 2,
      minWidth: 150,
      type: RECORD_TYPES.component,
      component: VehicleLink,
      props: result => ({
        label: `${result.vehicle_brand} ${result.vehicle_model}`,
        result,
      }),
    },
    {
      attributeName: 'booking_type',
      title: 'Booking type',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: BookingTypeBadge,
      props: booking => ({
        type: booking.is_unavailability ? 'unavailability' : booking.booking_type,
        isAutomatic: booking.is_automatic,
      }),
      minWidth: 170,
    },
    {
      title: 'Alerts',
      displayPriority: 1,
      minWidth: 70,
      type: RECORD_TYPES.template,
      isCollapseHidden: true,
      template: result => {
        const hasAlerts = concat(result.alert_reason).length;
        const template = `<ui-badge color="warning" solid="true">${concat(result.alert_reason).length}</ui-badge>`;

        return hasAlerts ? template : FALLBACK_MESSAGE.dash;
      },
    },
    {
      title: 'Alert reasons',
      type: RECORD_TYPES.component,
      component: AlertsTemplate,
      props: result => ({
        result,
        alerts: result.alert_reason,
      }),
      displayPriority: 0,
      minWidth: 280,
      transformValue: alerts => join(alerts, ', '),
    },
    {
      title: 'Fraud reported',
      attributeName: 'has_fraud',
      transformValue: value => {
        if (isUndefined(value)) {
          return FALLBACK_MESSAGE.dash;
        }
        return value ? 'Yes' : 'No';
      },
      displayPriority: 0,
    },
    {
      attributeName: 'real_start',
      title: 'Real start',
      displayPriority: 0,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'real_end',
      title: 'Real end',
      minWidth: 180,
      displayPriority: 0,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'real_duration_in_secs',
      title: 'Real duration',
      displayPriority: 0,
      minWidth: 180,
      transformValue: formatStopWatch,
    },
    {
      title: 'Mileage',
      displayPriority: 0,
      minWidth: 180,
      transformResult: result => (result.mileage ? `${result.mileage} ${result.mileage_unit}` : FALLBACK_MESSAGE.dash),
    },
    {
      attributeName: 'created_at',
      title: 'Creation date',
      displayPriority: 0,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'updated_at',
      title: 'Last update date',
      displayPriority: 0,
      minWidth: 180,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      attributeName: 'profile',
      title: 'User profile',
      displayPriority: 0,
      minWidth: 95,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ profile }) => ({
        text: sentenceCase(profile),
        color: GRAYSCALE.ground,
      }),
    },
    {
      attributeName: 'cs_operator',
      title: 'Operator',
      displayPriority: 0,
      minWidth: 80,
    },
    {
      attributeName: 'is_connected_car_platform',
      displayPriority: 0,
      title: 'Device type',
      minWidth: 70,
      transformValue: value => (toBoolean(value) ? 'Connected Car Platform device' : 'iBoxx'),
    },
  ];

  if (isUserVisible) {
    cells.splice(1, 0, userCell);
  }

  if (isLocationVisible) {
    cells.splice(6, 0, locationCell);
  }

  if (allowNonConnectedVehicles) {
    cells.push(nonConnectedCell);
  }

  return cells;
}
