export const BOOKING_FRAUD_COSTS = {
  damages: 'damages',
  fuel: 'fuel',
  missingEquipment: 'missing_equipment',
  missingFuelCard: 'missing_fuel_card',
  missingLicensePlate: 'missing_license_plate',
  missingKeys: 'missing_keys',
  penaltyChargeNotice: 'penalty_charge_notice',
  vehicleImpoundment: 'vehicle_impoundment',
  vehicleRecovery: 'vehicle_recovery',
};

export const BOOKING_USAGE_STATUS = {
  created: 'created',
  confirmed: 'confirmed',
  ready: 'ready',
  opened: 'opened',
  started: 'started',
  waitingEnd: 'waiting_end',
  waitingCancellation: 'waiting_cancellation',
  cancelled: 'cancelled',
  late: 'late',
  finished: 'finished',
  finishedLate: 'finished_late',
  notUsed: 'not_used',
  paused: 'paused',
};

export const BOOKING_STATUS = {
  pre_booked: 'pre_booked',
  pendingPayment: 'pending_payment',
  booked: 'booked',
  cancelled: 'cancelled',
  finished: 'finished',
};
